<template>
  <div v-if="!error" class="post">
    <div class="container">
      <div class="post__wrap">
        <h1>
          {{ postData.name }}
        </h1>
        <div class="body">
          {{ postData.description }}
        </div>
      </div>
    </div>
  </div>
  <NotFound v-else />
</template>

<script>
export default {
  name: "Order",
  data() {
    return {
      postData: {},
      error: false,
    };
  },
  async created() {
    //Получаем инфу из URL this.$route.params.id
    try {
      let response = await this.axios.get(
        "https://panacea-coffee.ru/wp-json/wc/store/products/" +
          this.$route.params.id
      );
      this.postData = response.data;
    } catch (error) {
      this.error = true;
      console.log("Ошибка: " + error);
    }
  },
};
</script>

<style scoped>
h1 {
  margin: 0;
  margin-bottom: 30px;
}
.post {
  padding: 50px 0;
  background: #f8f8f8;
}
</style>>
