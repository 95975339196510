<template>
  <div class="login">
    <div class="container">
      <form @submit.prevent="sendAuth">
        <input type="text" v-model="username" />
        <input type="text" v-model="password" />
        <button class="btn">Авторизоваться</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    async sendAuth() {
      const response = await this.axios
        .post("https://panacea-coffee.ru/wp-json/jwt-auth/v1/token", {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          sessionStorage.setItem("token", response.data.token);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
  },
};
</script>

<style scoped>
.login {
  padding: 50px 0;
}
input {
  margin-bottom: 20px;
}
.btn {
  background: black;
  color: white;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
}
form {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  margin: 0 auto;
}
</style>