<template>
  <Loading v-if="loading" />
  <div v-else class="posts">
    <div class="container">
      <h1>Записи</h1>
      <div class="posts__wrap">
        <div class="posts__item" v-for="order in orders" :key="order.id">
          <router-link :to="`/orders/${order.id}`">
            <div class="title">
              {{ order.name }}
            </div>
            <div class="body">
              {{ order.short_description }}
            </div>
          </router-link>
        </div>
        <div class="posts__item clearfix"></div>
        <div class="posts__item clearfix"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "./../components/Loading.vue";
export default {
  name: "Orders",
  data() {
    return {};
  },
  components: {
    Loading,
  },
  computed: {
    orders() {
      return this.$store.getters["orders/getOrders"];
    },
    loading() {
      return this.$store.getters["orders/getLoading"];
    },
  },
  created() {
    //Получаем инфу из URL this.$route.params.id
    this.$store.dispatch("orders/loadOrders");
  },
};
</script>

<style scoped>
h1 {
  margin: 0;
  margin-bottom: 30px;
}
.posts {
  padding: 50px 0;
  background: #f8f8f8;
}
.posts__wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.posts__item {
  flex-grow: 1;
  flex-basis: calc(33% - 10px);
  box-sizing: border-box;
  padding: 10px;
  margin: 5px;
  background: white;
  box-shadow: 0px 0px 15px 0px #00000029;
}
.posts__item .title {
  font-size: 20px;
}
.posts__item .body {
  margin-top: 10px;
}
.posts__item.clearfix {
  opacity: 0;
  height: 0px;
  padding: 0;
  overflow: hidden;
}
</style>>
